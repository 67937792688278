body {
  margin: 0;
  padding: 0;
  font-family: 'Lato', sans-serif;
}

.leaflet-container {
  height: 100vh;
  width: 100vw;
  margin: 0 0;
}
