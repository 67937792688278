.driver-details-overlay {
  color: #323643;
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100vw;
  background-color: #ffffff;
  z-index: 9999; }
  .driver-details-overlay img.truck-icon {
    position: absolute;
    top: -60px;
    right: -30px;
    width: 150px;
    z-index: 9999; }
  .driver-details-overlay .clickable {
    cursor: pointer; }
  .driver-details-overlay .filling-button {
    width: 100%;
    border-radius: 50px; }
  .driver-details-overlay .spacer {
    width: 100%;
    height: 20px; }
  .driver-details-overlay .expand-icon {
    transition: all 0.3s ease-in-out; }
    .driver-details-overlay .expand-icon.expanded {
      transform: rotate(180deg); }
