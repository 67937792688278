img.logo {
  position: absolute;
  z-index: 9999;
  width: 150px;
  height: auto;
  top: 20px;
  right: 20px; }

.css-icon {
  position: relative;
  width: 40px;
  height: 40px;
  transition: all 1s ease-in-out; }

.pin-icon img {
  width: 30px;
  height: auto; }

.gps-ring {
  position: absolute;
  top: 0;
  left: 0;
  border: 10px solid #323643;
  border-radius: 20px;
  height: 40px;
  width: 40px;
  animation: pulsate 1s ease-out;
  -webkit-animation: pulsate 1s ease-out;
  animation-iteration-count: infinite;
  -webkit-animation-iteration-count: infinite; }

.inner-ring {
  position: absolute;
  top: 13px;
  left: 13px;
  width: 14px;
  height: 14px;
  border-radius: 10px;
  background-color: #323643; }

@keyframes pulsate {
  0% {
    -webkit-transform: scale(0.1, 0.1);
    opacity: 0; }
  50% {
    opacity: 1; }
  100% {
    -webkit-transform: scale(1.2, 1.2);
    opacity: 0; } }

@-webkit-keyframes pulsate {
  0% {
    -webkit-transform: scale(0.1, 0.1);
    opacity: 0; }
  50% {
    opacity: 1; }
  100% {
    -webkit-transform: scale(1.2, 1.2);
    opacity: 0; } }
